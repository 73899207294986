<template>
  <div></div>
</template>

<script>
export default {
  components: {},
  props: {
    form: Object,
    loading: Boolean
  },
  data() {
    return {};
  },
  mounted() {
    //
  },
  methods: {
    //
  }
};
</script>

<style></style>
